import { singletonHook } from "react-singleton-hook";
import { useState } from "react";

export interface HeaderContext {
    iconEnum: HeaderContextIconEnum;
    titleEnum: HeaderContextTitleEnum;
}

export enum HeaderContextIconEnum {
    Unknown,
    Navigation,
    Back
}

export enum HeaderContextTitleEnum {
    Unkown,
    Dashboard,
    TrainingSessions,
    Profile,
    Followers,
    TrainingSessionDetail
}

let globalSetHeaderContextTitle = (headerContextTitle: HeaderContextTitleEnum) => { throw new Error('you are using globalSetHeaderContextTitle before you used useHeaderContext'); };
let globalSetHeaderContextIcon = (headerContextIcon: HeaderContextIconEnum) => { throw new Error('you are using setHeaderContextIcon before you used useHeaderContext'); };
let globalSetHeaderContext = (headerContextIcon: HeaderContextIconEnum, headerContextTitle: HeaderContextTitleEnum) => { throw new Error('you are using setHeaderContextIcon before you used useHeaderContext'); };

const initialHeaderContext: HeaderContext = {
    iconEnum: HeaderContextIconEnum.Navigation,
    titleEnum: HeaderContextTitleEnum.Unkown
};

export const useHeaderContext = singletonHook(initialHeaderContext, () => {

    const [headerContext, setHeaderContext] = useState<HeaderContext>(initialHeaderContext);
    // @ts-ignore
    // eslint-disable-next-line
    globalSetHeaderContextTitle = (headerContextTitle: HeaderContextTitleEnum) => {
        const newHeaderContext: HeaderContext = {
            titleEnum: headerContextTitle,
            iconEnum: headerContext.iconEnum
        }
        setHeaderContext(newHeaderContext);
    };
    // @ts-ignore
    globalSetHeaderContextIcon = (headerContextIcon: HeaderContextIconEnum) => {
        const newHeaderContext: HeaderContext = {
            titleEnum: headerContext.titleEnum,
            iconEnum: headerContextIcon
        }
        setHeaderContext(newHeaderContext);
    };
    // @ts-ignore
    globalSetHeaderContext = (headerContextIcon, headerContextTitle) => {
        const newHeaderContext: HeaderContext = {
            titleEnum: headerContextTitle,
            iconEnum: headerContextIcon
        }
        setHeaderContext(newHeaderContext);
    }
    // @ts-ignore
    return headerContext;
});

// eslint-disable-next-line
// @ts-ignore
export const setHeaderContextTitle = (headerContextTitle: HeaderContextTitleEnum) => globalSetHeaderContextTitle(headerContextTitle);
export const setHeaderContextIcon = (headerContextIcon: HeaderContextIconEnum) => globalSetHeaderContextIcon(headerContextIcon);
export const setHeaderContext = (headerContextIcon: HeaderContextIconEnum, headerContextTitle: HeaderContextTitleEnum) => globalSetHeaderContext(headerContextIcon, headerContextTitle);
