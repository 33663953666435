import React from 'react';
import { HighScore } from '../../../shared/shootingSession.model';
import { apiStates, useApi } from "../../../shared/useApi";

import styles from './highscorescard.module.scss';
import { Card, CardContent } from "@material-ui/core";

export const HighScoresCard = () => {

    const {apiData: { state: highScoreState, data: highScoreData }} = useApi<HighScore>('/session/highscores');



    return (
        <Card className="card">
            <CardContent>
                <h2>High Scores</h2>
                { highScoreState === apiStates.SUCCESS &&
                <div className={styles.content}>
                    <div className={styles.score}>
                        <span>60 Shot</span>
                        <h4>{highScoreData.maxSixty.toFixed(1)}</h4>
                    </div>
                    <div className={styles.score}>
                        <span>20 Shot</span>
                        <h4>{highScoreData.maxTwenty.toFixed(1)}</h4>
                    </div>
                    <div className={styles.score}>
                        <span>Any Serie</span>
                        <h4>{highScoreData.maxSerie.toFixed(1)}</h4>
                    </div>
                </div>
                }
            </CardContent>
        </Card>
    );
};
