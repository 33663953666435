import React, { Fragment } from 'react';
import './App.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { AirShotApp } from "./AirShot.App";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import {useLoader} from "../shared/useLoader";
import {useHeaderContext} from "../shared/useHeaderContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    }
}));

const App: React.FC = () => {

    const classes = useStyles();

    useLoader();
    useHeaderContext();

    const {
        isAuthenticated,
        loginWithRedirect
    } = useAuth0();

    if (isAuthenticated) {
        return (
           <AirShotApp></AirShotApp>
        );
    } else {
        return (
            <Fragment>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            AirShot
                        </Typography>
                        <Button color="inherit" onClick={loginWithRedirect}>Login</Button>
                    </Toolbar>
                </AppBar>
            </Fragment>
        );
    }
}

export default App;
