import React from 'react';
import { ArrowBack } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";


export const NavigationBarBack = () => {

    const history = useHistory();

    const navigateBack = () => {
        history.goBack();
    }

    const backStyles = {
        fontSize: "1rem"
    }

    return (
        <div>
            <IconButton onClick={navigateBack}>
                <ArrowBack />
                <span style={backStyles}>Back</span>
            </IconButton>
        </div>

    );
};
