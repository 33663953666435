import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TrainingSessionForm } from '../../shared/components/training-session-form/training-session-form';
import { ShootingSessionModel } from '../../shared/shootingSession.model';
import { usePostApi } from '../../shared/useApi';
import {
    HeaderContextIconEnum,
    HeaderContextTitleEnum,
    setHeaderContext,
    setHeaderContextIcon,
} from "../../shared/useHeaderContext";


export const ManualTrainingSessionRoot = () => {

    const history = useHistory();

    const {postData} = usePostApi();

    const handleSubmit = async (data: ShootingSessionModel) => {
        await postData(data, '/session');
        history.goBack();
    }
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setHeaderContext(HeaderContextIconEnum.Back, HeaderContextTitleEnum.TrainingSessionDetail);
    }, [])

    useEffect(() => {
        return () => {
            setHeaderContextIcon(HeaderContextIconEnum.Navigation);
        };
    }, []);

    return (
        <Fragment>
            <TrainingSessionForm onSubmit={handleSubmit}></TrainingSessionForm>
        </Fragment>
    );
};
