export enum ImageUploadStatusEnum {
    InProgress,
    Success,
    Error
}

export enum WeaponEnum {
    Rifle = 100,
    Pistol = 200
}

export interface ScoreModel {
    id?: string;
    counter: number;
    value: number;
}

export interface ProfileModel {
    auth0Name: string;
    email: string;
    id: string;
    username: string;
}


export type SerieModel = ScoreModel

export interface StorageImage {
    status: ImageUploadStatusEnum;
    blobName: string;
    etag: string;
    fullBlobUri: string;
}

export enum Period {
    Month,
    TwoMonth,
    SixMonth,
    OneYear,
    All
}

export interface ShootingSessionModel {

    id?: string;
    title: string;
    description: string;
    isDecimal: boolean;
    scores: SerieModel[];
    weapon: WeaponEnum;

    images: StorageImage[];

    profile?: ProfileModel;


    startDateTime?: Date;
    endDateTime?: Date;
}

export interface HighScore {
    maxSerie: number;
    maxTwenty: number;
    maxSixty: number;
}
