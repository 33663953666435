import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Header } from "../shared/Header";
import { Dashboard } from './dashboard/Dashboard';
import { Profile } from './profile/Profile';
import { TrainingSessionOverview } from "./training-sessions/training-sessions-overview/training-session-overview";
import { TrainingSessionEdit } from './training-sessions/training-session-edit/training-session-edit';
import { FollowersRoot } from './contacts/followers-root';
import { useLoader } from '../shared/useLoader';
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TrainingSessionDetail } from "./training-sessions/training-session-detail/training-session-detail";
import { ManualTrainingSessionRoot } from './manual-training-session/ManualTrainingSessionRoot';

const mainStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}

const useBackDropStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const AirShotApp = () => {

    const isLoading = useLoader();
    const backDropClasses = useBackDropStyles();

    return (
        <BrowserRouter>

            <Header></Header>

            <Backdrop className={backDropClasses.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <main style={mainStyle as React.CSSProperties} id="content" role="main" className="main">
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to="/dashboard"/>)}/>
                    <Route path="/profile" component={Profile}/>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/manual-training-session" component={ManualTrainingSessionRoot} />
                    <Route path="/training-session-overview" component={TrainingSessionOverview} />
                    <Route path="/session-edit/:id" component={TrainingSessionEdit} />
                    <Route path="/session-detail/:id" component={TrainingSessionDetail} />
                    <Route path="/followers" component={FollowersRoot} />
                </Switch>
            </main>

        </BrowserRouter>
    );
};
