export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0,
                // eslint-disable-next-line no-mixed-operators,eqeqeq
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static emptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
}


