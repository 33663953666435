import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
    HeaderContextIconEnum,
    HeaderContextTitleEnum, setHeaderContext,
} from "../../../shared/useHeaderContext";
import styles from './training-session-detail.module.scss';
import { Avatar } from "@material-ui/core";
import { apiStates, useApi } from "../../../shared/useApi";
import { ShootingSessionModel } from "../../../shared/shootingSession.model";
import ImageGallery from "react-image-gallery";

export const TrainingSessionDetail = () => {

    const { id: sessionId } = useParams<{id: string}>();

    const [imageHeight, setImageHeight] = useState<string>('300px');

    const { apiData } = useApi<ShootingSessionModel>(`/session/${sessionId}`);
    const shootingSession = apiData.data;

    const total = shootingSession?.scores.reduce((sum, current) => sum + current.value, 0);
    const numberOfShots = shootingSession?.scores.length * 10;
    const avg = total / numberOfShots;

    const images = shootingSession?.images.map(x => {
        if(!x)
            return [];

        return {
            original: x.fullBlobUri,
            originalHeight: imageHeight,
        }

    })

    const handleFullScreenChange = (isFullScreen: Boolean) => {
        setImageHeight(isFullScreen ? '600px' : '300px');
    }

    useEffect(() => {
        setHeaderContext(HeaderContextIconEnum.Back, HeaderContextTitleEnum.TrainingSessionDetail);
    }, [])

    return (
        <Fragment>
            {
                apiData.state === apiStates.SUCCESS &&
                <div className="content-base content-base-padding">
                    <div className={styles.header}>
                        <Avatar>
                            {shootingSession.profile.username.charAt(0)}
                        </Avatar>
                        <div className={styles.subHeader}>
                            <h4>{shootingSession.profile.username}</h4>
                            <span>{shootingSession.endDateTime}</span>
                        </div>
                    </div>
                    <div className={styles.textContent}>
                        <h3>{shootingSession.title}</h3>
                        {
                            shootingSession.description !== '' &&
                            <p>{shootingSession.description}</p>
                        }
                    </div>
                    <div className={styles.metadataContent}>
                        <div className={styles.metadataContentItem}>
                            <div className={styles.metadataContentItemInner}>
                                <h6># Shots</h6>
                                <span>{shootingSession.scores.length * 10}</span>
                            </div>

                        </div>
                        <div className={styles.metadataContentItem}>
                            <div className={styles.metadataContentItemInner}>
                                <h6>Total</h6>
                                <span>{total}</span>
                            </div>
                        </div>
                        <div className={styles.metadataContentItem}>
                            <div className={styles.metadataContentItemInner}>
                                <h6>Avg</h6>
                                <span>{avg.toFixed(3)}</span>
                            </div>
                        </div>
                    </div>
                    <h5>Scores</h5>
                    <div className={styles.metadataContent}>
                        {
                            shootingSession.scores.map((score, index) => (
                                <Fragment key={index}>
                                    <div className={styles.metadataContentItem}>
                                        <div className={styles.metadataContentItemInner}>
                                            <h6>Serie {index + 1}</h6>
                                            <span>{score.value}</span>
                                        </div>

                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>


                    <ImageGallery items={images} showThumbnails={false} showNav={false} showPlayButton={false} showBullets={true} useBrowserFullscreen={false}
                                  onScreenChange={handleFullScreenChange} className={styles.imageGallery}
                    />


                </div>
                }
        </Fragment>


    )
}
