import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { Guid } from '../../shared/guid';
import { apiStates, useApi } from "../../shared/useApi";
import { useHistory } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import styles from './dashboard.module.scss';
import { useAuth0 } from "@auth0/auth0-react";
import { StatisticsCard } from "./statistics/StatisticsCard";
import { HighScoresCard } from './highscores/highscoresCard';
import {
    HeaderContextIconEnum,
    HeaderContextTitleEnum, setHeaderContext,
} from "../../shared/useHeaderContext";

export const Dashboard = () => {

    const {
        user,
    } = useAuth0();

    const {apiData: { state, data }} = useApi('/profile');
    const history = useHistory();

    const [dialogOpen, changeDialogOpen] = useState(false);


    const completeProfileDialogClose = () => {
        changeDialogOpen(false);
        history.push('/profile');
    };

    const startTrainingSessionHandler = () => {
        history.push('/manual-training-session');
    }

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setHeaderContext(HeaderContextIconEnum.Navigation, HeaderContextTitleEnum.Dashboard);
    }, [])

    useEffect(() => {
        if(state === apiStates.SUCCESS) {
            if(data === Guid.emptyGuid()) {
                changeDialogOpen(true);
            }
        }
    }, [state, data]);

    return (
        <div className={`content-base-padding ${styles.rootDiv}`}>
            <div>
                <h1>Hello, {user.given_name}</h1>
            </div>

            <StatisticsCard></StatisticsCard>

            <HighScoresCard></HighScoresCard>



            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Welcome!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Welcome to AirShot, let&apos;s start with setting up your profile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={completeProfileDialogClose} color="primary">
                        Go to profile
                    </Button>
                </DialogActions>
            </Dialog>
            <Fab color="primary" aria-label="add" onClick={startTrainingSessionHandler}>
                <AddIcon />
            </Fab>
        </div>

    );
};
