import { singletonHook } from "react-singleton-hook";
import { useState } from "react";


let globalSetLoader = () => { throw new Error('you must useLoader before setting its state'); };

const initialLoading = false;

export const useLoader = singletonHook(initialLoading, () => {

    const [loader, setLoader] = useState(initialLoading);
    // @ts-ignore
    // eslint-disable-next-line
    globalSetLoader = setLoader;
    return loader;
});

// eslint-disable-next-line
// @ts-ignore
export const setLoader = isLoading => globalSetLoader(isLoading);
