import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { apiStates, useApi, usePutApi } from "../../../shared/useApi";
import { TrainingSessionForm } from "../../../shared/components/training-session-form/training-session-form";
import { ShootingSessionModel } from '../../../shared/shootingSession.model';
import {NavigationBarBack} from "../../../shared/components/navigation-bar-back/navigation-bar-back";

export const TrainingSessionEdit = () => {

    const { id: sessionId } = useParams<{id: string}>();

    const history = useHistory();

    const { apiData: { state: sessionState, data: sessionData }} = useApi<ShootingSessionModel>('/session/' + sessionId);

    const { putData } = usePutApi();

    const handleSubmit = async (data: ShootingSessionModel) => {
        data.id = sessionData.id;

        await putData(data, '/session');
        history.goBack();
    }

    return (
        <div>
            <NavigationBarBack></NavigationBarBack>
            { sessionState === apiStates.LOADING &&
            <span>fetching</span>
            }

            { sessionState === apiStates.SUCCESS &&
                <TrainingSessionForm initialShootingSession={sessionData} onSubmit={handleSubmit}></TrainingSessionForm>
            }
        </div>
    );
};
