import React, { Fragment } from 'react';
import {apiStates, useApi, useDeleteApi} from "../../shared/useApi";
import {PublicProfile} from "../../shared/profile.model";
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";

export const ManageFollowed = () => {

    const { apiData, refreshData } = useApi<PublicProfile[]>('/profile/followed');
    const { deleteData } = useDeleteApi()

    const handleUnFollowClicked = async(profile: PublicProfile) => {
        const deleteUrl = `/profile/unfollow?profileId=${profile.id}`;
        deleteData({}, deleteUrl)
            .then(() => {
                refreshData();
            })
    }

    return (
        <div className='content-base'>
            <List>
                {
                    apiData.state === apiStates.SUCCESS &&
                    apiData.data.map(((profile, index) => (
                        <Fragment key={index}>
                            <ListItem disableGutters={true} >
                                <ListItemText primary={profile.username}></ListItemText>
                                <Button type="submit" variant="outlined" color="primary" onClick={() => handleUnFollowClicked(profile)}>
                                    Ontvolgen
                                </Button>
                            </ListItem>
                            {apiData.data.length > index + 1 &&
                                <Divider></Divider>
                            }
                        </Fragment>
                    )))
                }
            </List>
        </div>
    )
}
