import React, { Fragment, SyntheticEvent, useState } from 'react';
import {
    Divider,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    List,
    ListItem, ListItemText,
    makeStyles,
    Theme
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import {useGetPagedApi, usePostApi} from "../../shared/useApi";
import {PublicProfile} from "../../shared/profile.model";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import styles from './search-people.module.scss';
import Button from "@material-ui/core/Button/Button";

interface Props {

}


const searchAPIDebounced = AwesomeDebouncePromise((text, callback: (text) => void) => {
    callback(text);
}, 1000);

export const SearchPeople = (props: Props) => {

    const [searchTake, setSearchTake] = useState(10);
    const [searchSkip, setSearchSkip] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [profiles, setProfiles] = useState<PublicProfile[]>([])

    const { getData } = useGetPagedApi()

    const { postData } = usePostApi();

    const searchNext = async (text: string) => {
        const searchUrl = '/profile/search-unfollowed';
        const queryParams = {
            take: searchTake,
            skip: searchSkip,
            searchText: text
        }
        const result = await getData(searchUrl, queryParams);
        const newProfiles = [
            ...profiles,
            ...result
        ];
        setProfiles(newProfiles);
    }

    const onChangeHandle = async (event: SyntheticEvent) => {
        const search = event.target['value'];
        if (search.length > 3) {
            setSearchTake(10);
            setSearchSkip(0)
            setSearchText(search);
            setProfiles([]);
            await searchAPIDebounced(search, searchNext);
        }
    }

    const handleFollowClicked = async(profile: PublicProfile) => {
        const followUrl = '/profile/follow';
        postData({followProfileId: profile.id}, followUrl)
            .then(result => {
                const toRemoveProfiles = [...profiles];
                const toRemoveIndex = toRemoveProfiles.indexOf(profile);
                toRemoveProfiles.splice(toRemoveIndex);
                setProfiles(toRemoveProfiles);
            });
    }

    return (
        <div className={'content-base ' + styles.content }>

            <FormControl>
                <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type="text"
                    onChange={onChangeHandle}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>



            <List>
                {
                    profiles.map((profile: PublicProfile, index) => (
                        <Fragment key={index}>
                            <ListItem disableGutters={true}>
                                <ListItemText primary={profile.username}></ListItemText>
                                <Button type="submit" variant="outlined" color="primary" onClick={() => handleFollowClicked(profile)}>
                                    Volgen
                                </Button>
                            </ListItem>
                            {profiles.length > index + 1 &&
                                <Divider></Divider>
                            }
                        </Fragment>

                    ))
                }
            </List>
        </div>
    );
};
