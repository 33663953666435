import React, {useCallback} from 'react';
import appConfig from "../appConfig.json";
import { useAuth0 } from "@auth0/auth0-react";

import axios, {AxiosRequestConfig} from "axios";
import {setLoader} from './useLoader';
import {useSnackbar} from "notistack";

export const apiStates = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

// const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
// const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE;

export const useDeleteApi = () => {
    const {
        getAccessTokenSilently
    } = useAuth0();

    const { enqueueSnackbar } = useSnackbar();

    const deleteData = async(putData, url: string) => {
        setLoader(true);

        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });

        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            console.log(e);
        }

        const config: AxiosRequestConfig = {
            headers: {'Authorization': `bearer ${accessToken}`},
            params: {
                id: putData.id
            }
        }

        try {
            const response = await axios.delete(fetchUrl, config);

            setLoader(false);

            const data = response.data;

            return Promise.resolve(data);
        }
        catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return Promise.reject(e);
        }
    }

    return { deleteData };
}

export const usePutApi = () => {
    const {
        getAccessTokenSilently
    } = useAuth0();

    const putData = async(putData, url: string) => {
        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });

        }catch (e) {
            console.log(e);
        }

        const response = await axios.put(fetchUrl, putData, {headers: {'Authorization': `bearer ${accessToken}`}});

        const data = response.data;

        return data;
    }

    return { putData };
}

export const usePostApi = () => {

    const {
        getAccessTokenSilently
    } = useAuth0();

    const { enqueueSnackbar } = useSnackbar();

    const postData = async(postData: any, url: string) => {
        setLoader(true);
        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });
        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return;
        }

        try {
            const response = await axios.post(fetchUrl, postData, {headers: {'Authorization': `bearer ${accessToken}`}});
            setLoader(false);

            const data = response.data;

            return Promise.resolve(data);
        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return Promise.reject(e)
        }
    }

    return { postData };
};

export const useGetPagedApi = () => {

    const {
        getAccessTokenSilently
    } = useAuth0();

    const { enqueueSnackbar } = useSnackbar();
    // useLoader();


    const getDataPaged = async(url: string, queryParams: any) => {


        setLoader(true);
        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });
        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return;
        }

        try {
            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `bearer ${accessToken}`
                },
                params: {
                    ...queryParams
                }
            }

            const response = await axios.get(fetchUrl, config);
            setLoader(false);

            const data = response.data;

            return data;
        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return null;
        }
    }

    return { getData: getDataPaged };
};

export const useImageUploadApi = (url: string) => {

    const {
        getAccessTokenSilently,
    } = useAuth0();

    const { enqueueSnackbar } = useSnackbar();

    const postImage = async (image: any) => {
        setLoader(true);

        const formData = new FormData();
        formData.append("image", image);

        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });
        }catch (e) {
            setLoader(false);
        }

        try {
            const response = await axios.post(fetchUrl, formData, {
                headers: {
                    'Authorization': `bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data"
                }
            });
            setLoader(false);

            return response;

        }catch (e) {
            setLoader(false);
            enqueueSnackbar('Oops, something went wrong, try again');
            return;
        }
        


    }

    return { postImage };


}

export const useApi = <T>(url: string) => {
    const [data, setData] = React.useState({
        state: apiStates.LOADING,
        error: '',
        data: null as T,
    });

    const {
        getAccessTokenSilently,
    } = useAuth0();

    const fetchData = useCallback(async () => {

        const fetchUrl = appConfig[process.env.REACT_APP_ENV].api + url;

        let accessToken = null;

        try {
            accessToken = await getAccessTokenSilently({
                audience: auth0Audience,
                scope: auth0Scope,
            });
        }catch (e) {
        }

        try {
            setLoader(true);

            const response = await axios.get(fetchUrl, {headers: {'Authorization': `bearer ${accessToken}`}});

            setLoader(false);
            const data = response.data;
            setData(previousData => ({
                ...previousData,
                state: apiStates.SUCCESS,
                data: data,
            }))
        } catch (e) {
            setLoader(false);
            setData(previousData => ({
                ...previousData,
                state: apiStates.ERROR,
                error: 'fetch failed'
            }));
        }
    }, [url, getAccessTokenSilently]);

    const refreshData = async () => {
        fetchData();
    }


    React.useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {apiData: data, refreshData };
};
