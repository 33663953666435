import React, {useEffect} from 'react';
import {HeaderContextTitleEnum, setHeaderContextTitle} from "../../../shared/useHeaderContext";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import {Tab} from "@material-ui/core";
import {TrainingSessionList} from "../session-list/training-session-list";
import {a11yProps, TabPanel} from "../../../shared/components/training-session-form/tabs";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {useHistory} from "react-router-dom";

export const TrainingSessionOverview = () => {
    const [value, setValue] = React.useState(0);

    const history = useHistory();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const startTrainingSessionHandler = () => {
        history.push('/manual-training-session');
    }

    useEffect(() => {
        setHeaderContextTitle(HeaderContextTitleEnum.TrainingSessions);
    }, [])


    return (
        <div className="">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                    <Tab label="Jij" {...a11yProps(0)} />
                    <Tab label="Volgend" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <TrainingSessionList apiUrl='/session/own' isReadonly={false}></TrainingSessionList>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TrainingSessionList apiUrl='/session/followed' isReadonly={true}></TrainingSessionList>
            </TabPanel>
            <Fab color="primary" aria-label="add" onClick={startTrainingSessionHandler}>
                <AddIcon />
            </Fab>
        </div>
    );
};
