import React, { Fragment, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import { AccountCircle, People } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import { useHistory } from "react-router-dom";
import { HeaderContextIconEnum, HeaderContextTitleEnum, useHeaderContext } from "./useHeaderContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export const Header = () => {

    const classes = useStyles();

    const headerContext = useHeaderContext();

    const {
        logout,
    } = useAuth0();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const accountOpen = Boolean(anchorEl);

    const navigateToFollowers = () => {
        history.push('followers');
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOptionsMenu = () => {
        setAnchorEl(null);
    };
    const handleNavigateToProfile = () => {
        handleCloseOptionsMenu();
        history.push('/profile');
    }
    const handleTogglePrimaryMenu = () => {
        setDrawerOpen(!drawerOpen);
    }
    const handleNavigateBack = () => {
        history.goBack();
    }

    const handleClosePrimaryMenu = () => {
        setDrawerOpen(false);
    }
    const handleNavigateToDashboard = () => {
        handleClosePrimaryMenu();
        history.push('/dashboard');
    }

    const handleNavigateToTrainingSessions = () => {
        handleClosePrimaryMenu();
        history.push('/training-session-overview');
    }

    const determineTitle = (titleEnum: HeaderContextTitleEnum) => {
        let title = 'AirShot'
        switch (titleEnum) {
            case HeaderContextTitleEnum.Dashboard: {
                title = 'Dashboard';
                break;
            }
            case HeaderContextTitleEnum.Followers: {
                title = 'Vrienden';
                break;
            }
            case HeaderContextTitleEnum.Profile: {
                title = 'Profiel';
                break;
            }
            case HeaderContextTitleEnum.TrainingSessions: {
                title = 'Training sessies';
                break;
            }
            case HeaderContextTitleEnum.TrainingSessionDetail: {
                title = 'Training sessie';
                break;
            }
        };
        return title;
    }

    const handleLogout = () => {
        handleCloseOptionsMenu();
        logout({ returnTo: window.location.origin });
    }

    const title = determineTitle(headerContext.titleEnum);

    return (
        <Fragment>
            <AppBar position={"relative"}>
                <Toolbar>
                    {
                        headerContext.iconEnum === HeaderContextIconEnum.Navigation &&
                        <IconButton edge="start" onClick={handleTogglePrimaryMenu} className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>

                    }
                    {
                        headerContext.iconEnum === HeaderContextIconEnum.Back &&
                        <IconButton edge="start" onClick={handleNavigateBack} className={classes.menuButton} color="inherit" aria-label="menu">
                            <ArrowBackIcon />

                        </IconButton>
                    }
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>

                    <IconButton
                        aria-label="followers of current user"
                        aria-controls="menu-appbar"
                        onClick={navigateToFollowers}
                        color="inherit"
                    >
                        <People />
                    </IconButton>

                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={accountOpen}
                            onClose={handleCloseOptionsMenu}
                        >
                            <MenuItem onClick={handleNavigateToProfile}>Mijn profiel</MenuItem>
                            <MenuItem onClick={handleCloseOptionsMenu}>Mijn account</MenuItem>
                            <MenuItem onClick={handleLogout}>Afmelden</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>

            </AppBar>
            <Drawer
                open={drawerOpen}
                onClose={() => {setDrawerOpen(false)}}
            >
                <MenuItem onClick={handleNavigateToDashboard}>Dashboard</MenuItem>
                <MenuItem onClick={handleNavigateToTrainingSessions}>Training Sessies</MenuItem>
            </Drawer>
        </Fragment>
    );
};
