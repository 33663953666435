import React, { useState } from 'react';
import { ShootingSessionModel } from "../../../shared/shootingSession.model";
import { MoreVert } from "@material-ui/icons";
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import Card from '@material-ui/core/Card/Card';
import {Avatar, CardActionArea, CardContent, CardMedia} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton/IconButton';
import { useHistory } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Menu from '@material-ui/core/Menu/Menu';
import { useDeleteApi } from "../../../shared/useApi";
import {useSnackbar} from "notistack";

interface Props {
    trainingSession: ShootingSessionModel;

    onDeleteSuccess: (shootingSession: ShootingSessionModel) => void;
    isReadonly: boolean;
}


export const TrainingSessionPreview = (props: Props) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { deleteData } = useDeleteApi();


    const total = props.trainingSession.scores.reduce((sum, current) => sum + current.value, 0);
    const numberOfShots = props.trainingSession.scores.length * 10;
    const avg = total / numberOfShots;


    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleOptionsClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }
    const goToEdit = (trainingSession: ShootingSessionModel) => {
        setAnchorEl(null);
        history.push('session-edit/' + trainingSession.id);
    }
    const handleDelete = async (trainingSession: ShootingSessionModel) => {
        setAnchorEl(null);
        await deleteData({id: trainingSession.id}, '/session');
        enqueueSnackbar('Session delete complete');
        props.onDeleteSuccess(trainingSession);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleDetailClick = (trainingSession: ShootingSessionModel) => {
        history.push(`session-detail/${trainingSession.id}`);
    }

    return (
        <Card
            className="card session-overview"
        >
            <CardActionArea onClick={() => handleDetailClick(props.trainingSession)} >
                <CardHeader
                    avatar={
                        <Avatar>
                            {props.trainingSession.profile.username.charAt(0)}
                        </Avatar>
                    }
                    action={
                        !props.isReadonly &&
                        <IconButton aria-label="settings" onClick={handleOptionsClick}>
                            <MoreVert/>
                        </IconButton>
                    }
                    title={props.trainingSession.profile.username}
                    subheader={props.trainingSession.endDateTime}
                />
                {
                    !props.isReadonly &&
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => goToEdit(props.trainingSession)}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDelete(props.trainingSession)}>Delete</MenuItem>
                    </Menu>

                }

                <CardContent className="card-content">
                    <div className="score-item">
                        <h6>Total</h6>
                        <span>{total.toFixed(1)}</span>
                    </div>
                    <div className="score-item">
                        <h6>Avg</h6>
                        <span>{avg.toFixed(3)}</span>
                    </div>
                    <div className="score-item">
                        <h6># Shots</h6>
                        <span>{numberOfShots}</span>
                    </div>

                </CardContent>
                {
                    !!props.trainingSession.images.length &&
                    <CardMedia
                        className="card-media"
                        image={props.trainingSession.images[0].fullBlobUri}
                    />
                }

            </CardActionArea>


        </Card>
    );
};
