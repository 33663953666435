import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { apiStates, useApi, usePostApi } from "../../shared/useApi";
import { Guid } from "../../shared/guid";
import { Controller, useForm } from "react-hook-form";
import Container from '@material-ui/core/Container';
import {Button, FormControlLabel} from '@material-ui/core';

import styles from './profile.module.scss';
import { useSnackbar } from "notistack";
import {
    HeaderContextIconEnum,
    HeaderContextTitleEnum,
    setHeaderContext
} from "../../shared/useHeaderContext";
import Switch from "@material-ui/core/Switch/Switch";

export class UserProfile
{
    id: string;
    auth0Name: string;
    username: string;
    firstName: string;
    email: string;
}

export const Profile = () => {

    const {apiData: { state, data: initialProfileData }} = useApi<any>('/profile');
    const { postData } = usePostApi()
    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, control, reset: resetProfileForm } = useForm({
        defaultValues: {username: '', isPublic: true}
    });

    const onSubmit = async (formData) => {
        try {
            const response: UserProfile = await postData(formData, '/profile');
            resetProfileForm({username: response.username})
            enqueueSnackbar('Save complete', {
                variant: 'success'
            });
        } catch (e) {

        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        setHeaderContext(HeaderContextIconEnum.Navigation, HeaderContextTitleEnum.Profile);
    }, [])

    useEffect(() => {
        if(state === apiStates.SUCCESS) {
            if(initialProfileData === Guid.emptyGuid()) {
                // create new userProfile
            } else {
                // prefill form
                resetProfileForm({...initialProfileData})
            }
        }
    }, [state, initialProfileData, resetProfileForm]);

    return (
        <Container className="content-base" maxWidth="sm">
            <form className={styles.profileForm} onSubmit={handleSubmit(onSubmit)}>
                <Controller className={styles.field} as={TextField} fullWidth label="Username" name="username" control={control} />
                <Controller
                    control={control}
                    name="isPublic"
                    render={({onChange, value}) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={value}
                                    onChange={async () => onChange(!value)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            className={styles.publicToggle}
                            labelPlacement="start"
                            label="Openbaar profiel"
                        />
                    )}
                />

                <Button type="submit"  variant="contained" color="primary">
                    Save
                </Button>
            </form>

        </Container>
    );
};
