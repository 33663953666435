import {makeStyles} from "@material-ui/core/styles";
import {ShootingSessionModel} from "../../../shared/shootingSession.model";
import React, {useState} from "react";
import {useGetPagedApi} from "../../../shared/useApi";
import {HeaderContextTitleEnum, setHeaderContextTitle} from "../../../shared/useHeaderContext";
import {TrainingSessionPreview} from "../training-session-preview/training-session-preview";
import Button from "@material-ui/core/Button/Button";


const useStyles = makeStyles((theme) => ({
    loadmoreButton: {
        width: '100%',
        marginBottom: '20px'
    }
}));

interface Props {
    apiUrl: string;
    isReadonly: boolean;
}

export const TrainingSessionList = (props: Props) => {

    const classes = useStyles();

    const [sessionsData, setSessionsData] = useState<ShootingSessionModel[]>([])
    const [fetchMeta, setFetchMeta] = useState({skip: 0, take: 5});
    const [hasMoreSessions, setHasMoreSessions] = useState(true);
    const fetchUrl = props.apiUrl;
    const { getData } = useGetPagedApi();

    const fetchAndStore = async (meta: {skip: number, take: number}) => {
        const data = await getData(fetchUrl, meta);
        if(data !== null) {
            if(data.length < meta.take)
                setHasMoreSessions(false);

            const newFetchMeta = {
                ...fetchMeta,
                skip: fetchMeta.skip + 5
            };
            setFetchMeta(newFetchMeta);

            const newSessionsData = [
                ...sessionsData,
                ...data
            ]
            setSessionsData(newSessionsData);
        }
    }

    React.useEffect(() => {
        if(fetchMeta.skip === 0) {
            fetchAndStore(fetchMeta);
        }
        setHeaderContextTitle(HeaderContextTitleEnum.TrainingSessions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSessionPreviewDelete = async (data: ShootingSessionModel) => {
        const index = sessionsData.findIndex(x => x.id === data.id);
        sessionsData.splice(index, 1);
        const newSessionsData = [...sessionsData];
        setSessionsData(newSessionsData);
    }

    const handleFetchSessions = async () => {
        await fetchAndStore(fetchMeta);
    }


    return (
        <div className="content-base-padding-top">
            {
                (!sessionsData.length && !hasMoreSessions) &&
                <p>No data here</p>
            }
            {
                sessionsData.map((field, index) => (
                    <TrainingSessionPreview isReadonly={props.isReadonly} key={index} trainingSession={field} onDeleteSuccess={handleSessionPreviewDelete}/>
                ))
            }

            {
                (!!sessionsData.length && hasMoreSessions) &&
                <Button className={classes.loadmoreButton} onClick={handleFetchSessions} type="submit" variant="contained" color="primary" >
                    Fetch more
                </Button>
            }


        </div>
    );
}
