import Card from '@material-ui/core/Card';
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { FormControl, FormControlLabel, InputLabel, MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select/Select';
import Button from '@material-ui/core/Button/Button';
import styles from './training-session-form.module.scss';
import Slider from '@material-ui/core/Slider/Slider';
import Switch from '@material-ui/core/Switch/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { useImageUploadApi } from "../../useApi";
import { StorageImage, ImageUploadStatusEnum, ScoreModel, ShootingSessionModel } from "../../shootingSession.model";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CardMedia from '@material-ui/core/CardMedia/CardMedia';
import Fab from '@material-ui/core/Fab/Fab';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
    initialShootingSession?: ShootingSessionModel;

    onSubmit: (shootingSession: ShootingSessionModel) => void;
}

export const TrainingSessionForm = (props: Props) => {

    const { postImage } = useImageUploadApi('/session/image-upload');
    const [images, setImages] = useState<StorageImage[]>(props.initialShootingSession ? props.initialShootingSession.images : []);


    const defaultValues = {
        title: '',
        description: '',
        gun: '',
        amountOfShots: 30,
        commas: true,
        series: new Array(6).fill({score: ''}),
        endDateTime: new Date().toISOString().replace(/.\d+Z$/g, "")
    }
    if(props.initialShootingSession) {
        defaultValues.title = props.initialShootingSession.title;
        defaultValues.description = props.initialShootingSession.description;
        defaultValues.gun = props.initialShootingSession.weapon.toString();
        defaultValues.amountOfShots = props.initialShootingSession.scores.length * 10;
        defaultValues.commas = props.initialShootingSession.isDecimal;
        defaultValues.series = [...props.initialShootingSession.scores.map(x =>  { return {score: x.value.toString()};})];
        defaultValues.endDateTime = props.initialShootingSession.endDateTime.toString();
    }

    // form stuff
    const {handleSubmit, control, formState, trigger} = useForm({
        mode: 'all',
        defaultValues: defaultValues
    })
    const { fields } = useFieldArray({
        control,
        name: 'series'
    });
    const watchAmountOfShots: number = useWatch({
        control,
        name: 'amountOfShots'
    });
    const watchEntireForm = useWatch({
        control
    });

    useEffect(() => { // if commas values changes, trigger validation manually
        if(formState.isDirty) {
            trigger();
        }
    }, [watchEntireForm.commas, formState.isDirty, trigger]);

    const marks = [
        {
            value: 10,
            label: '10',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 30,
            label: '30',
        },
        {
            value: 40,
            label: '40',
        },
        {
            value: 60,
            label: '60',
        },
    ];

    // add image stuff
    const handleImageChange = async (e) => {
        const files = [];
        for(let i = 0; i < e.target.files.length; i++) {
            files[i]= e.target.files[i];
        }

        const addedImages = [];

        for (const file of files) {
            const image = await addImage(file);
            if(image !== undefined)
                addedImages.push(image);
        }

        setImages([...images, ...addedImages]);

    }

    const addImage = async (file) => {
        const response = await postImage(file);
        if(response === undefined)
            return;


        const responseData = response.data;

        const image: StorageImage = {
            blobName: responseData.blobName,
            etag: responseData.eTag,
            fullBlobUri: responseData.fullBlobUri,
            status: ImageUploadStatusEnum.Success,
        }
        return image;
    }

    const deleteImage = async (image) => {
        const adjustedImages = images.filter(x => x !== image);
        setImages(adjustedImages);
    };


    const onSubmit = async (formData) => {
        const shootingSessionModel: ShootingSessionModel = {
            title: formData.title,
            description: formData.description,
            images: images,
            isDecimal: formData.commas,
            endDateTime: formData.endDateTime,
            scores: formData.series.map((x, index) => {
                const scoreModel: ScoreModel = {
                    counter: index + 1,
                    value: +x.score
                }
                return scoreModel;
            }),
            weapon: formData.gun
        };
        props.onSubmit(shootingSessionModel);
    }

    return (
        <div className={styles.manualTrainingSession}>
            <Card className="padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name={`title`}
                        defaultValue=''
                        rules={{
                            required: true
                        }}
                        render={({ onChange, value }) => (
                            <TextField
                                error={!!(formState?.errors?.title)}
                                label={'Titel'}
                                type="text"
                                value={value}
                                onChange={(event) => onChange(event.target.value)}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`description`}
                        defaultValue=''
                        render={({ onChange, value }) => (
                            <TextField
                                error={!!(formState?.errors?.description)}
                                label={'Beschrijving'}
                                type="text"
                                value={value}
                                multiline={true}
                                rows={3}
                                rowsMax={6}
                                onChange={(event) => onChange(event.target.value)}
                            />
                        )}
                    />
                    <FormControl>
                        <InputLabel id="gun-label">Wapen</InputLabel>
                        <Controller
                            labelId="gun-label"
                            id="gun-select"
                            as={Select}
                            control={control}
                            name="gun"
                            rules={{
                                required: true,
                            }}
                        >
                            <MenuItem value={100}>Karabijn</MenuItem>
                            <MenuItem value={200}>Pistool</MenuItem>
                        </Controller>
                    </FormControl>

                    <Controller

                        control={control}
                        name="endDateTime"

                        render={({ onChange, value }) => (
                            <TextField
                                label="Datum"
                                type="datetime-local"
                                value={value}
                                onChange={(event) => {
                                    console.log(event.target.value);
                                    onChange(event.target.value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        )}
                    />

                    <div className="formcontrol">
                        <InputLabel id="amount-of-shots-label" className="standalone-label">Aantal schoten</InputLabel>

                        <Controller
                            control={control}
                            name="amountOfShots"
                            render={({onChange, value}) => (
                                <Slider
                                    value={value}
                                    className="amount-of-shots-slider"
                                    aria-labelledby="discrete-slider-restrict"
                                    onChange={(event, newValue) => {
                                        onChange(newValue)
                                    }}
                                    step={null}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                    min={10}
                                    max={60}
                                />
                            )}
                        />
                    </div>

                    <div className="formcontrol commas-switch">
                        <Controller
                            control={control}
                            name="commas"
                            render={({onChange, value}) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={value}
                                            onChange={async () => onChange(!value)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement="start"
                                    label="Comma's"
                                />
                            )}
                        />
                    </div>

                    <div className="series">
                        {
                            fields.map((field, index) => (
                            <Fragment
                                key={field.id}
                            >
                                {
                                    (watchAmountOfShots / 10 > index) &&
                                    <Controller
                                        control={control}
                                        name={`series.${index}.score`}
                                        defaultValue={field.score}
                                        rules={{
                                            max: (watchEntireForm.commas) ? 109 : 100,
                                            required: true,
                                            pattern: (!watchEntireForm.commas) ? /^\d*$/ : null
                                        }}
                                        render={({ onChange, value }) => (
                                            <TextField
                                                error={!!(formState?.errors?.series?.[index])}
                                                label={`Serie ${index + 1}`}
                                                type="number"
                                                value={value}
                                                onChange={(event) => onChange(event.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        / {
                                                        (watchEntireForm.commas) ? '109' : '100'
                                                    }
                                                    </InputAdornment>
                                                }}
                                            />
                                        )}
                                    />

                                }
                            </Fragment>
                        ))
                        }
                    </div>

                    <div className="image-upload-part">

                        {

                            images.map((field: StorageImage, index) => (
                                <Card key={index}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={field.fullBlobUri}
                                        alt="green iguana"
                                        />
                                    <Fab color="secondary"  aria-label="edit" onClick={() => deleteImage(field)}>
                                        <DeleteIcon />
                                    </Fab>

                                </Card>
                            ))
                        }

                        <label htmlFor="upload-button" className="upload-button">
                            <AddAPhotoIcon />

                        </label>


                        <input
                            type="file"
                            id="upload-button"
                            multiple
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />

                    </div>

                    <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
                        Save
                    </Button>
                </form>
            </Card>
        </div>

    );
};
