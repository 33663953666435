import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from "./app/App";
import { Auth0Provider } from "@auth0/auth0-react";
import './custom.scss';
import { SnackbarProvider } from 'notistack';


const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE;



ReactDOM.render(
  <React.StrictMode>
      <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top'}} autoHideDuration={3000} >
          <Auth0Provider
              domain={auth0Domain}
              clientId={auth0ClientId}
              redirectUri={window.location.origin}
              audience={auth0Audience}
              scope={auth0Scope}
          >
              <App />

          </Auth0Provider>
      </SnackbarProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); TODO: later
