import { Tab } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs/Tabs';
import React, {useEffect} from 'react';
import {HeaderContextTitleEnum, setHeaderContextTitle} from "../../shared/useHeaderContext";
import AppBar from '@material-ui/core/AppBar/AppBar';
import { SearchPeople } from './search-people';
import {ManageFollowed} from "./manage-followed";
import {a11yProps, TabPanel} from "../../shared/components/training-session-form/tabs";

export const FollowersRoot = () => {


    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        setHeaderContextTitle(HeaderContextTitleEnum.Followers);
    }, [])

    return (
        <div className="">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                    <Tab label="Zoeken" {...a11yProps(0)} />
                    <Tab label="Mijn volgers" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <SearchPeople></SearchPeople>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ManageFollowed></ManageFollowed>
            </TabPanel>
        </div>
    );
};
