import React from 'react';
import styles from './statisticscard.module.scss';
import { LabelList, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { Period, ShootingSessionModel } from "../../../shared/shootingSession.model";
import { apiStates, useApi } from "../../../shared/useApi";
import { Card, CardContent } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Link } from 'react-router-dom';

export const StatisticsCard = () => {

    const [alignment, setAlignment] = React.useState<Period>(Period.Month);


    const handleChange = (event, period) => {
        setAlignment(period ? period : Period.Month);
    };

    const {apiData: { state: ownSessionsState, data: ownSessionsData }} = useApi<ShootingSessionModel[]>(`/session/ownbyperiod?period=${alignment}`);

    const sessionsData = ownSessionsData !== null ? ownSessionsData.map(x => {
        const total = x.scores.reduce((sum, current) => sum + current.value, 0);
        const avg = total / x.scores.length;
        const avgRounded = Math.round(avg * 10) / 10
        return {
            id: x.id,
            date: new Date(x.endDateTime).getTime(),
            score: avgRounded
        }
    }) : [];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltip}>
                    <div className={styles.date}>{fullDateFormatter(label)}</div>
                    <div className={styles.date}>{payload[0].payload.score.toString()}</div>
                    <Link to={`session-detail/${payload[0].payload.id}`}>Detail</Link><br/>
                    <Link to={`session-edit/${payload[0].payload.id}`}>Edit</Link>
                </div>
            );
        }

        return null;
    }

    const shortDateFormatter = (time: number) => {
        const date = new Date(time);
        return `${date.getDate()}/${date.getMonth() + 1}`;
    }
    const fullDateFormatter = (time: number) => {
        const date = new Date(time);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    return (
        <Card className="card">
            <CardContent>
                <div className={styles.header}>
                    <h2>Statistieken</h2>
                    <ToggleButtonGroup size="small" value={alignment} exclusive onChange={handleChange}>
                        <ToggleButton value={Period.Month}>
                            M
                        </ToggleButton>
                        <ToggleButton value={Period.TwoMonth}>
                            2M
                        </ToggleButton>
                        <ToggleButton value={Period.SixMonth}>
                            6M
                        </ToggleButton>
                        <ToggleButton value={Period.OneYear}>
                            Y
                        </ToggleButton>
                        <ToggleButton value={Period.All}>
                            ALL
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>

                { ownSessionsState === apiStates.SUCCESS &&
                    <div className={styles.graphic}>
                        { !!sessionsData.length ?
                            <ResponsiveContainer width="100%" aspect={3}>
                                <LineChart data={sessionsData} margin={{ right: 10, top: 10 }}>
                                    <XAxis dataKey="date" type="number" domain = {['auto', 'auto']} tickFormatter={shortDateFormatter} />
                                    <YAxis domain = {['auto', 'auto']} />
                                    <Line  type="linear" dataKey="score" stroke="#8884d8" activeDot={{r: 5}} >
                                    </Line>
                                    <Tooltip content={CustomTooltip} trigger="click"/>
                                </LineChart>
                            </ResponsiveContainer>
                            : <div>No data</div>
                        }
                    </div>
                }
            </CardContent>
        </Card>
    );
};
